import React, { useState } from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import { TabPeriodicReviews, TabAttachments } from './components'
import { TypographyCustom } from '../../../../../../../../../../../../components'

export const TabsCustom = () => {

  const [value, setValue] = useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const ContentBody = ({ children }) => {
    return (
      <Box sx={{width:'100%', minHeight:'680px', display:'flex', p:'16px', backgroundColor:'#FFFFFF', borderRadius:'8px',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        {children}
      </Box>
    )
  }

  const tabListArray = [
    {
      value:'1',
      name:'Revisões Períodicas',
      content: <TabPeriodicReviews/>,
      locked:false
    },
    {
      value:'2',
      name:'Anexos',
      content: <TabAttachments/>,
      locked:false
    },
  ]

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%'}}>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabListArray.map((item, index)=>(
            <Tab
              key={index}
              label={
                <TypographyCustom
                  text={item.name}
                  type="body-m"
                  colorText="#1A417D"
                />
              }
              value={item.value}
              sx={{textTransform:'none'}}
            />
          ))}
        </Tabs>
        {tabListArray.map((item, index)=>(
          <TabPanel key={index} value={item.value} >
            <ContentBody>
              {item.content}
            </ContentBody>
          </TabPanel>
        ))}
      </TabContext>
    </Box>

  )
}