import React from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { formatCurrency } from "../../utils/tools";
import { ErrorMessageForm } from "../ErrorMessageForm";
import { TypographyCustom } from "../TypographyCustom";

export function TextFieldCustomCurrency({
  label = "",
  required = false,
  name,
  control, 
  isDisabled = false,
  onBlur = () => {},
  disabledPrefix = false,
  textAlign = 'left' || 'right',
  sxText = {},
  textAlignLabel = 'left',
  min=0,
}) {
  const CurrencyInput = (props) => {
    const { onChange, value } = props;

    const handleChange = (event) => {
      let newValue = "0";

      if (event.target.value !== "") {
        newValue = event.target.value;
      }

      // Remove todos os caracteres não numéricos
      newValue = newValue.replace(/[^\d]/g, "");

      // Converte para número de ponto flutuante
      const floatValue = parseFloat(newValue) / 100;

      // Atualiza o valor no campo
      onChange(floatValue);
    };

    return <input {...props} value={value} onChange={handleChange} />;
  };

  const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: isDisabled ? "#C2C2C4" : "#374151",
    fontFamily: "Inter",
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:'3px' }}>
      {label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
          textAlign={textAlignLabel}
        />
      }
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              {...field}
              onChange={(v)=>{
                if(v >= min){
                  field.onChange(v)
                }
              }}
              variant="outlined"
              fullWidth
              value={formatCurrency(field.value)}
              error={!!error}
              InputProps={{
                inputComponent: CurrencyInput,
                startAdornment: !disabledPrefix && (
                  <InputAdornment position="start">
                    <Typography
                      sx={{
                        ...styleTypography,
                        ...sxText
                      }}
                    >
                      R$
                    </Typography>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 10,
                  height: '44px',
                  backgroundColor:'#FFFFFF',
                  border: '1px solid #6B7280',
                  ...styleTypography,
                  ...sxText
                },
              }}
              inputProps={{min: 0, style: { textAlign: textAlign }}}
              disabled={isDisabled}
              onKeyDown={(event) => {
                // Impede a digitação de caracteres não numéricos, exceto o separador decimal (ponto)
                const key = event.key;
                if (
                  !/[\d.]/.test(key) &&
                  key !== "Backspace" &&
                  key !== "Delete" &&
                  key !== "ArrowLeft" &&
                  key !== "ArrowRight" &&
                  key !== "Tab"
                ) {
                  event.preventDefault();
                }
              }}
              onBlur={(e) => {
                onBlur(e.target.value.replace(".", "").replace(",", "."));
              }}
            />
            {!!error && <ErrorMessageForm errorField={error}/>}
          </>
        )}
        rules={
          required && {
            required: "Campo obrigatório",
          }
        }
      />
    </Box>
  );
}
