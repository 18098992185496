import React, { createContext, useContext } from "react"
import { useServicesCareAfterCapillary } from "../hooks"

const ServicesCareAfterCapillaryContext = createContext()

export const useServicesCareAfterCapillaryContext = ()=>{
  return useContext(ServicesCareAfterCapillaryContext)
}

export function ServicesCareAfterCapillaryContextProvider({children}) {
  const {
    loading,
    dataSelect,
    handleRemoveById,
    handleSave
  } = useServicesCareAfterCapillary()

  return (
    <ServicesCareAfterCapillaryContext.Provider 
      value={{
        loading,
        dataSelect,
        handleRemoveById,
        handleSave
      }}
    >
      {!loading && children}
    </ServicesCareAfterCapillaryContext.Provider>
  )
}